.dropdown-menu.active{
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: 0.2s ease;
}
.dropdown-menu.inactive{
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20);
    transition: 0.2s ease;
}
