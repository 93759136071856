@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Archivo";
  }
}

.container {
  padding: 16px;
  margin: auto;
}
@media (min-width: 768px) {
  .container {
    padding: 80px;
  }
}

.animate-loading-bar {
  animation: loading-bar 2s infinite;
}

@keyframes loading-bar {
  0% {
    width: 0%;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 100%;
  }
}
